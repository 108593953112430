<template>
	<hr class="my-0" v-if="index" />
	<div class="d-flex justify-content-between p-4">
		<div class="form-check position-relative">
			<input type="radio" v-model="option" :value="null" class="form-check-input" :disabled="loading">
			<div class="form-check-label text-dark d-block ms-2">
				<a href="javascript:void(0)" class="stretched-link text-dark" @click="select">Sin especificar</a>
				<small class="d-block" v-if="option == null">Lo pagarás al momento de recibir el pedido</small>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue';
	import store from '@/store';

	export default {
		emits: ['select'],
		props: {
			loading: Boolean
		},
		setup(props, { emit }) {
			const payment = computed(() => store.getters['cart/payment']);
			const option = computed(() => {
				if(!payment.value) return null;
				else return payment.value.option;
			});

			const select = () => {
				emit('select', null);
			}

			return { payment, option, select };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>