<template>
	<h5 class="mb-4">¿Cuándo lo querés?</h5>
	<Skeletor height="100" class="card bg-white shadow-1 border rounded-2" v-if="!displayOptions" />
	<div class="card bg-white shadow-1 border rounded-2" v-if="displayOptions">
		<div class="d-flex justify-content-between p-4" v-if="timeServices.indexOf('immediate') == -1 && timeServices.indexOf('scheduled') == -1">
			<div class="form-check position-relative ps-0 text-warning">No hay opciones disponibles en este momento.</div>
		</div>
		<div class="d-flex justify-content-between p-4" v-if="timeServices.indexOf('immediate') != -1">
			<div class="form-check position-relative">
				<input type="radio" v-model="option" value="immediate" class="form-check-input" :disabled="loading">
				<div class="form-check-label text-dark d-block ms-2">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="select('immediate')">Lo antes posible</a>
					<small class="d-block" v-if="option == 'immediate'" v-html="immediateDelay"></small>
				</div>
			</div>
		</div>
		<hr class="my-0" v-if="timeServices.indexOf('immediate') != -1 && timeServices.indexOf('scheduled') != -1" />
		<div class="d-flex justify-content-between p-4" v-if="timeServices.indexOf('scheduled') != -1">
			<div class="form-check position-relative">
				<input type="radio" v-model="option" value="scheduled" class="form-check-input" :disabled="loading">
				<div class="form-check-label text-dark d-block ms-2">
					<a href="javascript:void(0)" class="stretched-link text-dark" @click="menuScheduleShow">Programado</a>
					<small class="d-block" v-if="option == 'scheduled'">Entrega el <b>{{ when.description }}</b>.</small>
				</div>
			</div>
			<a href="javascript:void(0)" class="text-dark" v-if="option == 'scheduled'" @click="menuScheduleShow">Editar</a>
		</div>
	</div>
	<cart-checkout-menu-schedule :services="services" @setSchedule="setSchedule" />
</template>

<script>
	import { ref, computed, onMounted } from 'vue';
	import store from '@/store';
	import moment from 'moment';
	import { Offcanvas } from 'bootstrap';
	import _ from 'lodash';

	export default {
		emits: ['updateLoading'],
		props: {
			loading: Boolean,
			displayOptions: Boolean,
			store: Object,
			services: Object
		},
		setup(props, { emit }) {
			const type = computed(() => store.getters['cart/type']);
			const when = computed(() => store.getters['cart/when']);
			const option = computed(() => {
				if(!when.value) return null;
				else return when.value.option;
			});
			const service = computed(() => {
				if(!type.value || !props.services) return null;
				else return _.find(props.services, (row) => { return row.type == type.value.option });
			});

			const timeServices = computed(() => {
				var response = [];
				if(!type.value || !service.value) return response;
				if(service.value.status == 'available') _.each(service.value.options, (option) => { if(option.status == 'available') response.push(option.type); });

				return response;
			});

			const immediateDelay = computed(() => {
				if(!type.value || !service.value) return null;
				var delay = Number(service.value.delay);

				if(type.value.option == 'DELIVERY') {
					var ready_from = moment().add((delay + Number(process.env.VUE_APP_DELIVERY_FROM)), 'minutes').format('HH:mm');
					var ready_to = moment().add((delay + Number(process.env.VUE_APP_DELIVERY_TO)), 'minutes').format('HH:mm');

					return `Entrega entre las <b class="text-dark-75">${ready_from} hs</b> y <b class="text-dark-75">${ready_to} hs</b>.`;
				}else{
					var ready_by = moment().add(delay, 'minutes').format('HH:mm');

					return `Entrega a partir de las <b class="text-dark-75">${ready_by} hs</b>.`;
				}
			});

			const select = (option) => {
				if(props.loading) return;

				emit('updateLoading', true);
				store.dispatch('cart/setWhen', { option: option });
				emit('updateLoading', false);
			}

			const setSchedule = (option) => {
				store.dispatch('cart/setWhen', option);
				menuSchedule.value.hide();
			}

			const menuSchedule = ref(null);
			onMounted(() => menuSchedule.value = new Offcanvas(document.getElementById('menu-schedule')));
			const menuScheduleShow = () => {
				if(!props.loading) menuSchedule.value.show();
			}

			return { type, when, timeServices, immediateDelay, option, select, setSchedule, menuScheduleShow };
		}
	}
</script>

<style scoped>
	.form-check-label {
		font-size: 1rem !important;
		font-weight: normal !important;
	}
</style>